<template>
  <div>
    <MotorTestItemTable :tableData="tableData" @edit-motor="editMotor" :loading="loading"  @apply-filters="applyFilters" :get-list="getList"/>
    <pagination-bar :totalRecords="totalRecords" :current-page="currentPage" :page-size="pageSize"
                    @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>
  </div>
</template>

<script>
import MotorTestItemTable
  from '@/views/ProductManage/ConfigManagement/components/Motor/TestItemComponent/MotorTestItemTable.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import api from '../../api'

export default {
  data() {
    return {
      tableData: [], // 电机数据
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      loading: false, // 加载状态
      filters: {}, // 存储过滤条件
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true; // 开始加载数据之前，设置loading为true

      // 构建 API 参数
      const params = { "product_type-eq": 0, page: this.currentPage, per_page: this.pageSize };
      if (Object.keys(this.filters).length > 0) {
        Object.assign(params, this.filters);
      }

      api.getTestItem(params).then(res => {
        this.tableData = res.data.map(item => {
          return {
            id: item.id,
            name: item.name,
            typeName: item.type,
           // typeName: item.type === 0 ? '动态测试' : '系统自检',
            documents: item.documents // 传递 documents 数组
          };
        });
        this.totalRecords = res.meta.total;
        this.currentPage = res.meta.current_page;
        this.pageSize = res.meta.per_page;
      }).catch(error => {
        this.$message.error(this.$t('ProductManage.ConfigManage.fetchDataFail'));
        throw error;
      }).finally(() => {
        this.loading = false; // 完成请求后，无论成功还是失败，都应设置loading为false
      });
    },

    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.getList(); // 重新加载数据
    },
    handlePageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      this.getList(); // 重新加载数据
    },
    applyFilters(filters) {
      // 更新 filters 对象，无论是否为空
      this.filters = filters;
      this.currentPage = 1; // 重新过滤时重置为第一页
      this.getList();
    },
  },
  components: {
    MotorTestItemTable,
    PaginationBar,
  },
};
</script>
